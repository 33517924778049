import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
const Header = React.lazy(() => import('./Header'));
const Footer = React.lazy(() => import('./Footer'));
const NotFound404 = React.lazy(() => import('./Components/Innerpage/NotFound404'));
const Home = React.lazy(() => import('./Components/Home/Home'));
const Pages = React.lazy(() => import('./Components/Innerpage/Pages'));

function App() {
  return (
    <div>
      <Router>
        <Suspense fallback={<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', backgroundColor: '#f0f0f0'}}><img src={`${process.env.PUBLIC_URL}/images/logogif.gif`} alt='Loading KANOPUS' style={{maxWidth: '50%', maxHeight: '50%', objectFit: 'contain'}}/></div>}>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path=":urlslug" element={<Pages />} />
            <Route path="*" element={<NotFound404 />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
